<template>
  <div class="main">
    <div class="header">
      <div>{{ usedInfo.comboTitle }}</div>
      <div>当前号码{{ msisdn }}</div>
    </div>
    <van-tabs v-model="active" animated swipeable>
      <van-tab title="流量" style="padding-bottom:50px;background:#fff;">
        <div style="background-color: white; padding: 10px">
          <div class="pack">
            <div><span style="color:#0c0;font-size:14px;">● </span>当前生效套餐</div>
            <van-row class="pack-content">
              <van-col span="12">
                <span>已用{{ usedInfo.usedFlowPercent }}%</span>
                <br />
                <span>{{ usedInfo.usedFlowUnit }}</span>
              </van-col>
              <van-col span="12">
                <span>剩余{{ usedInfo.residueFlowPercent }}%</span>
                <br />
                <span>{{ usedInfo.residueFlowUnit }}</span>
              </van-col>
            </van-row>
            <div class="pack-detail">
              <div>{{ usedInfo.comboTitle }}</div>
              <div v-if="usedInfo.totalFlow != null">
                共{{ usedInfo.totalFlow }}MB
              </div>
              <div style="color:#c7c7c7 !important" v-if="usedInfo.effectiveDate != null">
                生效时间：{{ usedInfo.effectiveDate }} 
              </div>
              <div v-if="usedInfo.expiryDate != null">
                失效时间：{{ usedInfo.expiryDate }} 
              </div>
            </div>
          </div>
        </div>
        <!-- <div style="margin-top: 10px; padding: 10px">
          <van-button
            size="large"
            style="margin-right: 10px"
            color="#ffab00"
            :url="'/flowAdd'+wUrl"
            >充流量</van-button
          >
          <van-button
            size="large"
            style="margin-right: 10px; margin-top: 10px"
            color="#2860b3"
            >上不了网?</van-button
          >
        </div> -->
        <div class="otherOombo">
          <div><span style="color:#ffab00;font-size:14px;">● </span>待生效套餐</div>
          <div class="pack-detail" v-for="(item,index) in usingInfo" :key="index">
              <div>{{ item.comboTitle }}</div>
              <div v-if="item.totalFlow != null">
                共{{ item.totalFlow }}MB
              </div>
              <div v-if="item.effectiveDate != null">
                生效时间：{{ item.effectiveDate }} 
              </div>
              <div v-if="item.expiryDate != null">
                失效时间：{{ item.expiryDate }} 
              </div>
          </div>
          <div v-if="usingInfo.length==0" style="color:#c7c7c7 !important;padding:10px">
            暂无套餐
          </div>
        </div>
      </van-tab>


      <van-tab title="语音">
        <div style="background-color: white; padding: 10px">
          <div>当前生效套餐</div>
          <div class="pack">
            <van-row class="pack-content">
              <van-col span="12">
                <span>已用{{ usedInfo.usedVoicePercent }}%</span>
                <br />
                <span>{{ usedInfo.usedVoiceUnit }}</span>
              </van-col>
              <van-col span="12">
                <span>剩余{{ usedInfo.residueVoicePercent }}%</span>
                <br />
                <span>{{ usedInfo.residueVoiceUnit }}</span>
              </van-col>
            </van-row>
            <div class="pack-detail">
              <div>{{ usedInfo.comboTitle }}</div>
              <div v-if="usedInfo.totalFlow != null">
                共{{ usedInfo.totalVoice }}分钟
              </div>
              <div style="color:#c7c7c7 !important" v-if="usedInfo.effectiveDate != null">
                生效时间：{{ usedInfo.effectiveDate }}
              </div>
              <div v-if="usedInfo.expiryDate != null">
                失效时间：{{ usedInfo.expiryDate }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div style="margin-top: 10px; padding: 10px">
          <van-button
            size="large"
            style="margin-right: 10px"
            color="#ffab00"
            :url="'/voiceAdd'+wUrl"
            >充语音</van-button
          >
          <van-button
            size="large"
            style="margin-right: 10px; margin-top: 10px"
            color="#2860b3"
            >打不了电话?</van-button
          >
        </div> -->
      </van-tab>
    </van-tabs>
    <common-footer />
  </div>
</template>

<script>
import { selectCardUsed,selectCardUsing } from "@/api/card";
import { calculateFlow } from "@/utils/convert";
import { parseTime } from "@/utils/index";
import commonFooter from "@/views/common/footer.vue";
import Cookies from "js-cookie";
export default {
  name: "Used",
  components: {
    commonFooter,
  },
  data() {
    return {
      active: 2,
      msisdn: this.$store.getters.msisdn,
      iccId: this.$store.getters.iccId,
      usedInfo: {
        usedFlowUnit: "0M",
        residueFlowUnit: "0M",
        usedFlowPercent: "0",
        residueFlowPercent: "0",
        usedVoiceUnit: "0分",
        residueVoiceUnit: "0分",
        usedVoicePercent: "0",
        residueVoicePercent: "0",
      },
      usingInfo:[],
      wUrl:null
    };
  },
  created() {
    
    this.wUrl = '/'+localStorage.getItem('resFlog1')
    
    console.log('wid',this.wUrl)
    this.getCardUsed();
    this.getCardUsing();
  },
  methods: {
    getCardUsing() {
      let iccid = Cookies.get('card-id');
      selectCardUsing(iccid).then((res) => {
        if (res&&res.length>0) {
          this.usingInfo = res.map((item)=>{
            return calculateFlow(item)
          })
          console.log('待生效套餐',res)
        }
      });
    },
    getCardUsed() {
      let iccid = Cookies.get('card-id');
      selectCardUsed(
        iccid,
        parseTime(new Date(), "{y}{m}")
      ).then((res) => {
        if (res != null) {
          let cardUsed = calculateFlow(res);
          this.usedInfo = cardUsed;
          console.log('流量使用情况',cardUsed)
        }
      });
    },
  },
};
</script>

<style scoped>
.van-tabs__nav {
  background-color: #3185ff;
}
.van-tab--active {
  color: #ffffff !important;
}
.van-tab {
  color: #000;
}
.van-tabs__line {
  background-color: #ffffff;
}
.van-tabs__nav--line {
  padding-bottom: 10px;
}
.van-tab__pane {
  padding: 10px;
  padding-bottom: 50px !important;
}
</style>
<style scoped>
.main {
  background-color: #f2f2f2;
  height: 100%;
  font-family: pingfang;
}
.header {
  background-color: #3185ff;
  padding: 10px 20px;
  color: white;
}
.header div:last-child {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  line-height: 1rem;
}
.pack {
  padding: 10px;
  border: 1px solid #e8e7e5;
  border-radius: 5px;
  margin-top: 10px;
}
.pack-content {
  text-align: center;
}
.pack-content div span:first-child {
  font-size: 0.8rem;
  color: #777777;
}
.pack-content div span:last-child {
  font-size: 2rem;
  line-height: 3rem;
}
.pack-detail {
  padding: 10px;
  background-color: #f6f8f7;
  border-radius: 5px;
  margin-top: 10px;
}
.pack-detail div:first-child {
  font-size: 1rem;
}
.pack-detail div:nth-child(2) {
  font-size: 0.9rem;
  color: #8d9190;
}
.pack-detail div:nth-child(3) {
  text-align: right;
  font-size: 0.9rem;
  color: #8d9190;
}
.pack-detail div:last-child {
  text-align: right;
  font-size: 0.9rem;
  color: #8d9190;
}
.otherOombo{
  padding: 10px;
  margin: 10px;
  background: #fff;
  border-radius: 10px;
  border: 1px #e8e7e5 solid;
}
</style>
